// Avenir Next
@font-face {
  font-family: 'Avenir Next';
  src: url('/assets/fonts/Medium/AvenirNext-Medium.woff2') format('woff2'),
    url('/assets/fonts/Medium/AvenirNext-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('/assets/fonts/Bold/AvenirNext-Bold.woff2') format('woff2'),
    url('/assets/fonts/Bold/AvenirNext-Bold.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('/assets/fonts/Demi/AvenirNext-Demi.woff2') format('woff2'),
    url('/assets/fonts/Demi/AvenirNext-Demi.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('/assets/fonts/Regular/AvenirNext-Regular.woff2') format('woff2'),
    url('/assets/fonts/Regular/AvenirNext-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('/assets/fonts/Ultralight/AvenirNext-Ultralight.woff2') format('woff2'),
    url('/assets/fonts/Ultralight/AvenirNext-Ultralight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}


// Avenir Next LT Pro
@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('/assets/fonts/Medium/AvenirNextLTPro-Medium.woff2') format('woff2'),
    url('/assets/fonts/Medium/AvenirNextLTPro-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('/assets/fonts/Bold/AvenirNextLTPro-Bold.woff2') format('woff2'),
    url('/assets/fonts/Bold/AvenirNextLTPro-Bold.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('/assets/fonts/Demi/AvenirNextLTPro-Demi.woff2') format('woff2'),
    url('/assets/fonts/Demi/AvenirNextLTPro-Demi.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('/assets/fonts/Regular/AvenirNextLTPro-Regular.woff2') format('woff2'),
    url('/assets/fonts/Regular/AvenirNextLTPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('/assets/fonts/Ultralight/AvenirNextLTPro-UltLt.woff2') format('woff2'),
    url('/assets/fonts/Ultralight/AvenirNextLTPro-UltLt.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}