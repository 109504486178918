@use './styles/fonts' as *;

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Avenir Next', sans-serif;
  background-color: #ffffff;
  width: 100%;
  position: relative;
}
